import * as React from 'react'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { IoCaretForward as PlayIcon } from 'react-icons/io5'
import { HiDownload as DownloadIcon } from 'react-icons/hi'

import {
  ComponentWrapperContext,
  ResourceClickHandlerContext
} from '@react-ui/context'
import { ErrorWithDetailedMessage } from '../../notification/notification'
import { AiFillEyeInvisible as EyeIcon } from 'react-icons/ai'
import { PiCaretUpDownFill } from 'react-icons/pi'
import ToggleSwitch from '@builder/react-ui/scheduler/toggle-switch'

export type StateType = 'completed' | 'current' | 'upcoming'
export interface SessionCardProps {
  id
  episodeNumber?: number
  numDaysFromStart: number
  skipped?: boolean
  date: any
  sessionTitle: string
  thumbnail?: any
  type: string
  session: any
  status: StateType
  weekendEpisodeNumber?: number
  trainingEpisodeNumber?: number
  totalWeekendSessions?: number
}

const SessionCard: FunctionComponent<
  SessionCardProps & { dragHandleProps?: any }
> = ({
  id,
  episodeNumber,
  date,
  sessionTitle,
  thumbnail,
  type,
  session,
  skipped,
  status,
  numDaysFromStart,
  weekendEpisodeNumber,
  trainingEpisodeNumber,
  totalWeekendSessions,
  dragHandleProps
}) => {
  const { resourceClickHandler, videoClickHandler } = useContext(
    ResourceClickHandlerContext
  )
  const [toggleValue, setToggleValue] = useState(false)
  const handleToggle = () => {
    console.log('Toggled:', !toggleValue)
    setToggleValue((prevValue) => !prevValue) // Toggle the value
  }

  const { locale } = useContext(ComponentWrapperContext)
  const formattedDate = new Date(date).toLocaleDateString(locale, {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  })

  const dayLabel = new Date(date).toLocaleDateString(locale, {
    weekday: 'long'
  })

  const Icon =
    skipped === true ? EyeIcon : type === 'document' ? DownloadIcon : PlayIcon
  const SizedIcon = () => <Icon size='2.5rem' />
  const [error, setError] = useState<ErrorWithDetailedMessage>(null)
  const clickHandler = () => {
    if (skipped === true) {
      return
    } else {
      try {
        console.log('clicked')
        if (type == 'video' && videoClickHandler) {
          videoClickHandler(session)
        } else {
          resourceClickHandler(session)
        }
      } catch (error) {
        const { message } = error
        error.detailedMessage = message

        error.message =
          `There was a issue with this resource` +
          `${session?.attachedDocument?.title ? `: ${session.attachedDocument.title}` : ''}`

        setError(error)
      }
    }
  }
  const qaName = 'drag-drop-item' + id
  return (
    <>
      <div
        data-qa-name={`session-card-${id}`}
        className='flex p-4 gap-5 rounded-lg bg-alpha-white border border-alpha-oatmeal-10 border-solid cursor-auto'
        style={{
          border:
            status === 'current'
              ? `solid 2px #000000`
              : 'border-alpha-oatmeal-10 border-solid'
        }}
      >
        <div onClick={clickHandler} className='click-container flex gap-5'>
          {thumbnail ? (
            <>
              <div
                data-qa-name='session-thumbnail'
                className='h-32 aspect-video bg-cover bg-center flex justify-center items-center text-alpha-grey-light'
                style={{
                  backgroundImage: thumbnail ? `url(${thumbnail})` : ''
                }}
              >
                <SizedIcon />
              </div>
            </>
          ) : null}

          <div className='title-container'>
            {trainingEpisodeNumber ? (
              <p data-qa-name='training-label'>
                {$localize`:schedule.label|Schedule - Training Number@@trainingNumber:Training ${trainingEpisodeNumber}:episode:`}
              </p>
            ) : null}
            {episodeNumber ? (
              <p data-qa-name='episode-label'>
                {$localize`:schedule.label|Schedule - Episode Number@@episodeNumber:Episode ${episodeNumber}:episode:`}
              </p>
            ) : null}
            {weekendEpisodeNumber ? (
              <p data-qa-name='weekend-label'>
                {$localize`:schedule.label|Schedule - Weekend Number@@weekendNumber:• Holy Spirit Sessions ${weekendEpisodeNumber}:episode: out of ${totalWeekendSessions}:sessions:`}
              </p>
            ) : null}

            <p data-qa-name='session-title'>{sessionTitle}</p>
          </div>
        </div>

        <div className='date-container'>
          <p data-qa-name='day-label'>{dayLabel}</p>
          <p data-qa-name='date-label'>{formattedDate}</p>
        </div>
        <ToggleSwitch
          toggleDisabled={false}
          toggleValue={toggleValue}
          handleToggle={handleToggle}
        />
        {/* Drag icon inside the card */}
        <div {...dragHandleProps} className='cursor-grab flex items-center'>
          <PiCaretUpDownFill size={24} data-qa-name={qaName} />
        </div>
      </div>
    </>
  )
}

export default SessionCard
