import { FunctionComponent } from 'react'

import * as React from 'react'
import Switch from 'react-switch'

interface toggleProps {
  toggleDisabled: boolean
  toggleValue: boolean
  handleToggle
}

const ToggleSwitch: FunctionComponent<toggleProps> = ({
  toggleValue,
  toggleDisabled,
  handleToggle
}) => {
  return (
    <Switch
      disabled={toggleDisabled}
      data-qa-name='scheduleSkip'
      checked={toggleValue}
      onChange={() => {
        if (!toggleDisabled) {
          handleToggle()
        }
      }}
      offColor='#3D3937' // Background when off
      onColor='#00BBA5' // Background when on
      offHandleColor='#ffffff' // Thumb color when off
      onHandleColor='#ffffff' // Thumb color when on
      className='border-2 border-gray-500' // Optional Tailwind classes
      uncheckedIcon={null} // Removes checkmark when off
      checkedIcon={null} // Removes checkmark when on
    />
  )
}

export default ToggleSwitch
