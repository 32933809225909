import { HtmlParser, RecursiveVisitor, visitAll } from '@angular/compiler'
import { ɵparseCookieValue } from '@angular/common'
import { LOCALE_ID, StaticProvider } from '@angular/core'
import { environment } from './environments/environment'
import { LOCALE_DATA } from './common/lang/locale'

/**
 *
 *
 * @todo
 *
 * ! current issue is that if the number of placeholders don't match the order of the output gets pretty weird
 *
 * given template text "`{$START_PARAGRAPH}If you are running {$PRODUCTNAME} using a DVD, an old version of the product, or running in another language, then you can continue to register and create your course. However, you {$START_TAG_STRONG}will not get access to the downloadable videos.{$CLOSE_TAG_STRONG}{$CLOSE_PARAGRAPH}{$START_PARAGRAPH}This will allow you to get access to additional features and tools, and to allow your course to show up on the Find a Course map to be promoted to potential guests.{$CLOSE_PARAGRAPH}{$START_PARAGRAPH}If you do not need access to the downloadable videos, press CONTINUE below. Otherwise, press Cancel to purchase the new product.{$CLOSE_PARAGRAPH}`"
 * you cannot add a another paragraph
 *
 * refer to files:
 *    @angular\compiler\esm2015\src\i18n\serializers\placeholder.js
 *    @angular\compiler\esm2015\src\i18n\i18n_parser.js
 *    @angular\compiler\esm2015\compiler.js*
 *
 * 	and others in that i18n src
 *
 *
 */

const htmlParser = new HtmlParser()

const TAG_TO_PLACEHOLDER_NAMES = {
  A: 'LINK',
  B: 'BOLD_TEXT',
  BR: 'LINE_BREAK',
  EM: 'EMPHASISED_TEXT',
  H1: 'HEADING_LEVEL1',
  H2: 'HEADING_LEVEL2',
  H3: 'HEADING_LEVEL3',
  H4: 'HEADING_LEVEL4',
  H5: 'HEADING_LEVEL5',
  H6: 'HEADING_LEVEL6',
  HR: 'HORIZONTAL_RULE',
  I: 'ITALIC_TEXT',
  LI: 'LIST_ITEM',
  LINK: 'MEDIA_LINK',
  OL: 'ORDERED_LIST',
  P: 'PARAGRAPH',
  Q: 'QUOTATION',
  S: 'STRIKETHROUGH_TEXT',
  SMALL: 'SMALL_TEXT',
  SUB: 'SUBSTRIPT',
  SUP: 'SUPERSCRIPT',
  TBODY: 'TABLE_BODY',
  TD: 'TABLE_CELL',
  TFOOT: 'TABLE_FOOTER',
  TH: 'TABLE_HEADER_CELL',
  THEAD: 'TABLE_HEADER',
  TR: 'TABLE_ROW',
  TT: 'MONOSPACED_TEXT',
  U: 'UNDERLINED_TEXT',
  UL: 'UNORDERED_LIST'
}

class MyVisitor extends RecursiveVisitor {
  visitElement(el, context) {
    const upperTag = el.name.toUpperCase()
    const placeholder = TAG_TO_PLACEHOLDER_NAMES[upperTag] || 'TAG_' + upperTag
    const res = visitAll(this, el.children, context).join('')
    return `{$START_${placeholder}}${res}{$CLOSE_${placeholder}}`
  }
  visitText(text, context) {
    return text.value
  }
  visitContainer(container, context) {
    const nodes = []
    container.children.forEach((node) => nodes.push(...node.visit(this)))
    return nodes
  }
  serialize(nodes) {
    return [].concat(...nodes.map((node) => node.visit(this))).join('')
  }
}
const visitor = new MyVisitor()

/**
 * Parse our translations into a dictionary
 */

export const prepareDictionary = (data) => {
  const dict = {}
  for (const id in data) {
    let translatedValue = data[id].target

    // only parse if theres an opening html tag
    if (translatedValue.indexOf('<') >= 0) {
      const parsed = htmlParser.parse(translatedValue, '')
      const prepared = visitor.serialize(parsed.rootNodes)

      translatedValue = prepared
    }

    /*
     * migrate old style variables
     * @todo this will be un-neccessary when the POEditor translations are migrated to use {$VARIABLE} instead of {{variable}}
     */
    if (translatedValue.indexOf('{{') >= 0) {
      translatedValue = translatedValue.replace(
        /({{([^}]+)}})/g,
        (full, match, inner) => {
          return `{$${inner.toUpperCase()}}`
        }
      )
    }
    dict[id] = translatedValue
  }
  return dict
}

/**
 * The only way to reliably load data before DI Services are instantiated is to make an XHR request before bootstrapping the application.
 * We do that here in order to preload any data that's critical to bootstrapping. ( Translations )
 */
export const preloadTranslations = () => {
  return new Promise<StaticProvider[]>((resolve, reject) => {
    let requestedLocale = getTargetLocale()

    const request = new XMLHttpRequest()

    request.addEventListener('load', function () {
      try {
        const response = JSON.parse(this.responseText)
        if (!response.locale) {
          reject(response)
          return
        }

        const sitePath = this.getResponseHeader('mya-site-path')
        if (sitePath) {
          document.cookie =
            'myaSitePath=' +
            encodeURIComponent(sitePath) +
            ';path=/;expires=' +
            new Date(new Date().getTime() + 604800000).toUTCString()
        }
        // store the locale
        setLocale(response.locale)

        /**
         * Resolve with an array of providers that can be used on bootstrap
         */
        resolve([
          {
            provide: LOCALE_DATA,
            useValue: response
          },
          {
            provide: LOCALE_ID,
            useValue: response.ngLocale
          }
        ])
      } catch (error) {
        reject(error)
      }
    })
    const blogIdentifier: string = document.location.pathname.substring(
      0,
      location.pathname.indexOf('/', 1) + 1
    )
    request.addEventListener('error', (e) => reject(e))
    request.open(
      'GET',
      `${environment.BFF_HOST}${blogIdentifier}wp-json/wp/v2/localeData/${requestedLocale}`
    )
    request.setRequestHeader('Accept', 'application/json')
    request.setRequestHeader('rtml-language', requestedLocale)
    request.setRequestHeader('x-csrf', '1')
    request.withCredentials = true
    request.send()
  })
}

const LOCALE_STORAGE_KEY = 'rt_current_language'
const WEEK_IN_MILLISECONDS = 604800000
const COOKIES_ENABLED = navigator?.cookieEnabled

/**
 * Set the current locale in storage
 */
export const setLocale = (locale) => {
  if (COOKIES_ENABLED) {
    const cookieString: string =
      encodeURIComponent(LOCALE_STORAGE_KEY) +
      `=${locale};path=/;expires=` +
      new Date(new Date().getTime() + WEEK_IN_MILLISECONDS)
    document.cookie = cookieString
  } else {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale)
  }
}

/**
 * Get the locale from query param or local storage
 */
const getTargetLocale = () => {
  let requestedLocale = ''

  const docQuery = document.location.search
  const pattern = /[?&]lang=([^&$]+)/

  // if it's in the url param use it
  if (pattern.test(docQuery)) {
    const langMatches = docQuery.match(pattern)
    requestedLocale = langMatches[1]
    const newUrl =
      document.location.origin +
      document.location.pathname +
      docQuery.replace(pattern, '')
    history.replaceState({ lang: requestedLocale }, '', newUrl)
  }
  // otherwise check in cookie or local storage
  else {
    requestedLocale = COOKIES_ENABLED
      ? (ɵparseCookieValue(document.cookie, LOCALE_STORAGE_KEY) ?? '')
      : (localStorage.getItem(LOCALE_STORAGE_KEY) ?? '')
  }

  return requestedLocale
}
